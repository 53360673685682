import React from 'react';
import { Link } from 'react-router-dom';
import { tags, blogs } from './blogindex.js'

export function blogBody() {
    return (
        <div className="text-lg text-gray-400">
            <p className="mb-6">
                Embraced across the industry, digitization is rapidly transforming automotive engineering. Manufacturers welcomed the flexibility, cost savings and rapid product development provided by software-based engineering and the consumer-focused features enabled by online connectivity.
            </p>
            <p className="mb-6">
                Digitization however, carried a dark passenger, unleashing a host of cybersecurity threats on manufacturers who were unprepared for their new roles as software and internet companies.
            </p>
            <p className="mb-6">
                Even as manufacturers confront these threats, progress is hindered by radically differing development methodologies practiced by traditional engineering teams and the digital newcomers. Agile practices have not mated well with the deliberate and careful processes established over years of manufacturing experience.
            </p>
            <figure className="mb-6">
                <img className="w-full" src={require('./images/bridgegap.jpg').default} width="768" height="432" alt="Bridge with Gap" />
            </figure>
            <p className="mb-6">
                Systemic change can be difficult for OEMs, who risk repeating the quality failures encountered a decade ago with the onset of drive by wire systems. Toyota’s infamous unintended acceleration crisis remains a cautionary tale of how an organisation’s culture and values can be just as important as technical skills in producing safe and reliable products.
            </p>
            <h4 className="h4 mb-1 text-gray-200">Prevention is better than cure, and cheaper</h4>
            <p className="mb-6">
                Just as a healthy diet and regular exercise reduce the likelihood of disease, some of the most effective cybersecurity controls are also preventive and reduce the likelihood that a product will develop vulnerabilities. These build a kind of cybersecurity fitness.
            </p>
            <p className="mb-6">
                Policies and procedures that correctly prioritise, support and resource cybersecurity, are all good examples of preventive measures. As are the development of cybersecurity capabilities through training, recruitment, and supplier engagement.
            </p>
            <p className="mb-6">
                This is why the recently issued WP.29/UNR 155 cybersecurity regulation requires certification of an organisation's cybersecurity management maturity as a prerequisite to approval of specific vehicle types.
            </p>
            <figure className="mb-6">
                <img className="w-full" src={require('./images/jogging.jpg').default} alt="Woman jogging on road" />
            </figure>
            <h4 className="h4 mb-1 text-gray-200">Prioritizing Healthy Cybersecurity</h4>
            <p className="mb-6">
                The caveat is that preventive measures are often implemented poorly. They take time to establish and mature, and it’s not always clear how to measure their effectiveness. Sadly, it’s sometimes easier to wait until an underlying issue becomes a serious problem, since at that point few will question the urgency and expense.
            </p>
            <p className="mb-6">
                There is a strong temptation to focus on code review and penetration testing to detect and then remediate vulnerabilities. This is akin to waiting for surgical intervention for a disease that could have been prevented with healthier practices. Testing and review are of course essential activities, but without preventive measures, they are expensive and painful solutions to avoidable problems.
            </p>
            <p className="mb-6">
                Of course, just as diet and exercise programs rarely survive in the long term without disciplined lifestyle changes, so cybersecurity must become a focus and integral facet of an organisation’s values to ensure ongoing support and influence. To target support where needed most, such an organisational ‘lifestyle change’ requires ongoing monitoring of its effectiveness at every level of the organisation.
            </p>
            
            <h4 className="h4 mb-1 text-gray-200">
                Data-driven cybersecurity fitness 
            </h4>
            <p className="mb-6">
                Results can’t be achieved overnight. As with physical fitness, cybersecurity fitness requires a tailored programme that starts from a clear understanding of current capabilities towards well defined and measurable goals.
            </p>
            <figure className="md:float-right ml-6 mt-0 mb-4">
                <img className="mx-auto" src={require('./images/measure.jpg').default} width="300" alt="Man measuring waist" />
            </figure><p className="mb-6">
                Ongoing measurement of the capabilities as they mature relies on analysis of their effectiveness in reducing the frequency and severity of vulnerabilities. Equally vital is anticipating the potential damage of existing weaknesses and designing supplementary mitigations to protect against negative outcomes, even while building capabilities to reduce them.
            </p>
            <p className="mb-6">
                The success of such a programme of course depends on understanding how an organisations cybersecurity maturity influences product cybersecurity.  A model of these organisational cybersecurity dynamics is the most powerful tool in realizing the benefits of preventive cybersecurity, and the key to achieving cybersecurity fitness.
            </p>
        </div>
    );
}