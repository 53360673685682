export const tags= { 
  digitization: {
    index: 'digitization',
    tag:'Digitization', 
    className:"inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-orange-600 hover:bg-orange-700 transition duration-150 ease-in-out",
  },
  connectivity: {
    index: 'connectivity',
    tag:'Connectivity',
    className:"inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-blue-500 hover:bg-blue-600 transition duration-150 ease-in-out"
  },
  safety: {
    index: 'safety',
    tag:'Safety',
    className:"inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-green-500 hover:bg-green-600 transition duration-150 ease-in-out"
  },
  chh: {
    index: 'chh',
    tag:'Car Hacking',
    className:"inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-red-500 hover:bg-red-600 transition duration-150 ease-in-out"
  },
  csmaturity: {
    index: 'csmaturity',
    tag:'Cybersecurity Maturity',
    className:"inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-purple-600 hover:bg-purple-700 transition duration-150 ease-in-out",
  },
  cybercrime: {
    index: 'cybercrime',
    tag:'Cybercrime',
    className:"inline-flex text-center text-red-600 font-bold py-1 px-3 rounded-full bg-black hover:bg-gray-900 transition duration-150 ease-in-out",
  },
  cstraining: {
    index: 'cstraining',
    tag:'Training',
    className:"inline-flex text-center text-gray-100 py-1 px-3 rounded-full bg-gray-600 hover:bg-purple-700 transition duration-150 ease-in-out",
  }
}

export const authors= { 
  pkw: {
    name:'Peter Wesley',
    image: require("../images/aboutus/pw.jpg").default,
  },
  pl: {
    name:'Patrick Laird',
    image: require("../images/aboutus/pl.jpg").default,
  },
}

export const blogs=[ {
  title:'Connectivity - The Jekyll and Hyde of the Modern Car', 
  abstract:"The past decade has witnessed explosive growth in digital connectivity with unprecedented advances in functionality and convenience for consumers, especially in the automotive industry. Yet while promising to fulfill our dreams of intelligent and efficient transportation, it has also brought us nightmares of invaded privacy and killer vehicles.",
  keyImage: require('./images/jhcap.jpg').default,
  tags: [tags.connectivity, tags.safety, tags.chh ],
  index: 0,
  author: 'pkw',
  date:'21 Feb 2022',
  featured: true,
  body: require('./connectivity_jnh.js').blogBody
}
,{
  title:'A Cure Worse than the Disease', 
  abstract:"The upsurge of cybersecurity risks grew in unison with the rise of connected vehicles. What if consumers had the ability to kill the connection to the cloud? Would it stop the hackers? Could it make connected cars safer?",
  keyImage: require('./images/internetkill.jpg').default,
  tags: [tags.connectivity, tags.safety],
  index: 1,
  author: 'pl', 
  date:'3 Mar 2022',
  body: require('./kill_switch.js').blogBody

},{
  title:"Healing Digitization's Dark Passenger", 
  abstract:"Digitization has brought a host of cybersecurity challenges to manufacturer's processes and engineering culture. Preventive cybersecurity is the key to tackling these challenges and achieving a safe and secure transformation.",
  keyImage: require('./images/darkpassenger.jpg').default,
  index: 2,
  tags: [tags.csmaturity, tags.digitization],
  author:'pkw',
  date:'14 Mar 2022',
  body: require('./darkpassenger.js').blogBody
},{
  title:"Automotive Cybercrime - The gathering storm", 
  abstract:"Cybercrime is targeting the automotive industry even as it faces a chronic shortage of cybersecurity skills. How can the industry rapidly develop the skills needed to thwart the cybercriminals?",
  keyImage: require('./images/gatheringstorm.jpg').default,
  index: 3,
  tags: [tags.cybercrime, tags.csmaturity, tags.cstraining],
  author:'pkw',
  date:'21 Mar 2022',
  body: require('./gatheringstorm.js').blogBody
},
] 