import React from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import Header from '../partials/Header';
import PageIllustration from '../partials/PageIllustration';
import BlogFeatured from '../partials/BlogFeatured';
import Newsletter from '../partials/Newsletter';
import Footer from '../partials/Footer';
import BlogList from '../partials/BlogList';

function BlogSearch() {
  const { tag, author  } = useParams();

  // if (index >= blogs.length) {
    console.log("got tag, author", tag, author)
    // return(
      // <Redirect to="/blog"/>
    // )
  // } else

   return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
          <PageIllustration />
        </div>
        {/*  Page sections */}
        <div className="pt-20 pb-12 md:pt-20 md:pb-20">
        <BlogList tag={tag} author={author}/>
        </div>
        <Newsletter />
      </main>
      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default BlogSearch;