import React from 'react';
import { Link } from 'react-router-dom';
import { blogs, tags, authors } from '../blogs/blogindex.js'
import Blog from '../pages/Blog.js';
import BlogList from './BlogList.js';

function BlogFeatured() {
  
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-0 md:pt-40">

          {/*  Page header */}
          {/* <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h2" data-aos="fade-up">Catchy title needed, apply within...</h1>
          </div> */}

          {/*  Featured article */}
          {blogs.filter(item => item.featured).map((featuredItem, featuredIndex) => {
            return(
              <article key={featuredIndex} className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
                <Link to={"/blog-post"+"/"+featuredIndex}  className="relative block group" data-aos="fade-right" data-aos-delay="200">
                  <div className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none" aria-hidden="true"></div>
                  <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                    <img className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out" 
                      src={featuredItem.keyImage} width="540" height="303" alt="Featured Image" 
                    />
                  </figure>
                </Link>
                <div data-aos="fade-left" data-aos-delay="200">
                  <header>
                    <div className="mb-3">
                      <ul className="flex flex-wrap text-xs font-medium -m-1">
                      {featuredItem.tags.map((tagitem, index) => (
                          <li className="m-1">
                            <Link to={"/blog/tag/"+tagitem.index} className={tagitem.className}>{tagitem.tag}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <h3 className="h3 text-2xl lg:text-3xl mb-2">
                      <Link to={"/blog-post"+"/"+featuredIndex}  className="hover:text-gray-100 transition duration-150 ease-in-out">{featuredItem.title}</Link>
                    </h3>
                  </header>
                  <p className="text-lg text-gray-400 flex-grow">{featuredItem.abstract}</p>
                  <footer className="flex items-center mt-4">
                    <Link to={"/blog/by/"+featuredItem.author}>
                      <img className="rounded-full hover:border-gray-500 border-2 border-gray-700 flex-shrink-0 mr-4" src={authors[featuredItem.author].image} width="40" height="40" alt="Author Image" />
                    </Link>
                    <div>
                      <Link to={"/blog/by/"+featuredItem.author} className="font-medium text-gray-300 hover:text-gray-100 transition duration-150 ease-in-out">{authors[featuredItem.author].name}</Link>
                      <span className="text-gray-700"> - </span>
                      <span className="text-gray-500">{featuredItem.date}</span>
                    </div>
                  </footer>
                </div>
              </article>
            
          )})}

         

          {/*  Pagination */}
          {/* <nav className="flex justify-center pt-16" role="navigation" aria-label="Pagination Navigation">
            <ul className="inline-flex flex-wrap font-medium text-sm -m-1">
              <li className="m-1">
                <span className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-4 rounded-full text-gray-500">Prev</span>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-300 hover:bg-purple-600 transition-colors duration-150 ease-in-out">1</Link>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-300 hover:bg-purple-600 transition-colors duration-150 ease-in-out">2</Link>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-300 hover:bg-purple-600 transition-colors duration-150 ease-in-out">3</Link>
              </li>
              <li className="m-1">
                <span className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-500">...</span>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-2 rounded-full text-gray-300 hover:bg-purple-600 transition-colors duration-150 ease-in-out">12</Link>
              </li>
              <li className="m-1">
                <Link to="#" className="inline-flex h-10 min-w-10 justify-center items-center bg-gray-800 px-4 rounded-full text-gray-300 hover:bg-purple-600 transition-colors duration-150 ease-in-out">Next</Link>
              </li>
            </ul>
          </nav> */}

        </div>
      </div>
    </section>
  );
}

export default BlogFeatured;